import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
// import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { sendWciDatalayerEvent } from './_googleTagging'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'
import * as S from './_styles'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import Picture from 'src/components/Picture'

const Index = () => {
  const domReady = useDomReady()
  const width = useWidth()

  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <S.AntecipacaoFgtsSection>
        <div className='container'>
          <S.Row className='row'>
            {domReady && width < 768 && (
              <Picture
                pictureClassName='section_image'
                width='100%'
                minHeight='176px'
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/banner-antecipacao-fgts-whatsapp-sm/image.webp'
              />
            )}
            <S.TextsWrapper className='col-12 col-md-6 col-lg-5'>
              <S.Title>
                Antecipação<br />Saque-Aniversário FGTS a partir de R$100
              </S.Title>
              <S.Description>
                Antecipa aí seu FGTS e libere um dinheiro que já é seu. É rápido e fácil. Simule pelo WhatsApp e veja quanto você tem pra receber.
              </S.Description>
              <Button
                fullWidth
                onClick={sendWciDatalayerEvent}
                className='whatsapp_simulation'
              >
                Simular pelo Whatsapp
                <IconsSwitch
                  icon='ic_whatsapp'
                  customPath='brands/'
                  className='whatsapp_simulation__icon'
                  size='MD'
                  color='white'
                  lib='interco'
                />
              </Button>
            </S.TextsWrapper>
          </S.Row>
        </div>
      </S.AntecipacaoFgtsSection>
    </Layout>
  )
}

export default Index
